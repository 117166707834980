//---------------------------------------------
//
//  Table of Contents
//
//    1. Basics
//    2. Page Layout
//    3. Components
//
//---------------------------------------------

//---------------------------------------------
// 1. Basics
//---------------------------------------------

@import "variables";
@import "bootstrap";
@import "fonts";

//---------------------------------------------
// 2. Page Layout
//---------------------------------------------
body {
  font-family: 'Lexend', sans-serif;
  font-size: 1rem;
}

.section__padding {
  padding-top: 80px;
  padding-bottom: 80px;
}

.bg-gray {
  background-color: #f2f2f2;
}

.btn-warning {
  background-color: #f15529;
  border-color: #f15529;
  color: #fff;
}

.btn-warning:hover {
  background-color: #f15529;
  border-color: #f15529;
  color: #fff;
}

.btn-outline-warning {
  background-color: transparent;
  border-color: #f15529;
  color: #f15529;
}

.btn-outline-warning:hover {
  background-color: #f15529;
  border-color: #f15529;
  color: #fff;
}

.rating__box-stars,
.rating-stars {
  display: flex;
  gap: 1px;
}

//---------------------------------------------
// 3.Global Components
//---------------------------------------------
.section__padding {
  padding-top: 80px;
  padding-bottom: 80px;
}

.padding__top {
  padding-top: 80px;
}

.padding__bottom {
  padding-bottom: 80px;
}

.section__heading {
  margin: 0 auto;
  max-width: 980px;
  text-align: center;
}

@import "../js/owlcarousel/scss/owl.carousel";
@import "../js/owlcarousel/scss/owl.theme.default";

//---------------------------------------------
// 3.Hero
//---------------------------------------------
.hero {
  padding-top: 100px;
  background-color: #0b2051;
  padding-bottom: 150px;

  .intro {
    margin: 0 auto;
    max-width: 850px;
    text-align: center;

    h1 {
      font-size: 60px;
      line-height: 120%;
      font-weight: 700;
      color: #fff;
      margin-bottom: 2rem;
    }

    p {
      font-size: 18px;
      line-height: 150%;
      font-weight: 300;
      color: #fff;
      margin-bottom: 2rem;
    }
  }

  .ratings {
    display: flex;
    grid-gap: 1rem;
    justify-content: center;

    .rating__box {
      display: flex;
      padding: 10px 20px 10px 15px;
      align-items: center;
      border: 1px solid rgba(196,211,248,.15);
      border-radius: 6px;
      background-color: hsla(0,0%,100%,.06);
      color: #fff;
      width: 200px;

      .rating__box-content {
        display: flex;
        flex-direction: column;
      }

      .rating__box-icon {
        margin-right: 10px;
      }

      .rating__box-top {
        display: flex;
        align-items: center;
      }

      .rating__box-stars {
        display: flex;
        margin-left: 5px;
      }

      .rating__box-service {
        text-align: left;
      }
    }
  }
}

//---------------------------------------------
// 3.Team
//---------------------------------------------
.team {
 .owl-item {
    filter: blur(2px);
    transform: scale(.75);
    transition: all .5s;
  }

  .owl-item.active{
    filter: blur(0);
    transform: scale(1);
    z-index: 2;
    transition: all .5s;
  }

  .writer__card {
    display: flex;
    width: 737px;
    height: 351px;
    padding: 18px 30px;
    box-shadow: 1px 0 17px 4px rgba(54,127,211,.18);
    align-items: center;
    border: 1px solid #c4d3f8;
    border-radius: 6px;
    background-color: #fff;
    margin: 0 auto;

    .writer__content {
      display: flex;
      padding-top: 12px;
      padding-bottom: 12px;
      padding-left: 30px;
      justify-content: center;
      border-left: 1px solid #e1eaff;
      flex-direction: column;

      .writer__content-title {
        h6 {
          margin-bottom: 25px;
          color: #0b2051;
          font-size: 18px;
          font-weight: 600;
        }

        p {
          font-size: 14px;
          line-height: 150%;
          color: #575757;
          margin-bottom: 0;
        }
      }
    }

    .writer__grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    .writer__left {
      .writer__left-top {
        display: flex;
        padding-top: 12px;
        padding-right: 30px;
        padding-bottom: 12px;
      }
    }

    .writer__name {
      margin-left: 20px;

      .rating__box {
        display: flex;
        align-items: center;
        margin-right: 5px;
      }
    }

    .writer__avatar {
      img {
        width: 95px;
        height: 95px;
        padding: 8px;
        border: 1px solid #f15529;
        border-radius: 50%;
      }
    }

    .writer__stats {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-bottom: 1.5rem;

      .finished__orders {
        display: flex;
        align-items: center;
        padding-right: 10px;
        padding-bottom: 10px;
        border-right: 1px solid #dee2e6;
        border-bottom: 1px solid #dee2e6;

        span {
          font-size: 13px;
          line-height: 150%;
          color: #575757;
          margin-bottom: 0;

          &:first-child {
            font-size: 16px;
            margin-right: 1rem;
            color: #0b2051
          }

          &:last-child {
            color: #575757;
          }
        }
      }

      .success__rate {
        display: flex;
        align-items: center;
        padding-left: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #dee2e6;

        span {
          font-size: 13px;
          line-height: 150%;
          color: #575757;
          margin-bottom: 0;

          &:first-child {
            font-size: 16px;
            margin-right: 1rem;
            color: #0b2051;
          }

          &:last-child {
            color: #575757;
          }
        }
      }

      .degree {
        display: flex;
        align-items: center;
        padding-left: 10px;
        padding-top: 9px;

        span {
          font-size: 13px;
          line-height: 150%;
          color: #575757;
          margin-bottom: 0;

          &:first-child {
            font-size: 16px;
            margin-right: 1rem;
            color: #0b2051;
          }

          &:last-child {
            color: #575757;
          }
        }
      }

      .reviews {
        display: flex;
        align-items: center;
        padding-right: 10px;
        padding-top: 9px;
        border-right: 1px solid #dee2e6;

        span {
          font-size: 14px;
          line-height: 150%;
          color: #575757;
          margin-bottom: 0;

          &:first-child {
            font-size: 16px;
            margin-right: 1rem;
            color: #0b2051;
          }

          &:last-child {
            color: #575757;
          }
        }
      }
    }

    .writer__content-action {
      display: flex;
    }

    .writer__expertise {
      max-width: 100%;
      margin: 0 auto;
    }
  }

  .team__nav {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2rem;

    .btn-outline-secondary {
      box-shadow: none;
    }
  }
}

.team__content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: space-evenly;

  .profile__card {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 30px 30px;
    box-shadow: 1px 0 17px 4px rgba(54,127,211,.18);
    border: 1px solid #c4d3f8;
    border-radius: 6px;
    background-color: #fff;

    .writer__avatar {
      img {
        width: 95px;
        height: 95px;
        padding: 8px;
        border: 1px solid #f15529;
        border-radius: 50%;
      }
    }

    .rating__box {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .writer__stats {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-bottom: 1.5rem;

      .finished__orders {
        display: flex;
        align-items: center;
        padding-right: 10px;
        padding-bottom: 10px;
        border-right: 1px solid #dee2e6;
        border-bottom: 1px solid #dee2e6;

        span {
          font-size: 13px;
          line-height: 150%;
          color: #575757;
          margin-bottom: 0;

          &:first-child {
            font-size: 16px;
            margin-right: 1rem;
            color: #0b2051
          }

          &:last-child {
            color: #575757;
          }
        }
      }

      .success__rate {
        display: flex;
        align-items: center;
        padding-left: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #dee2e6;

        span {
          font-size: 13px;
          line-height: 150%;
          color: #575757;
          margin-bottom: 0;

          &:first-child {
            font-size: 16px;
            margin-right: 1rem;
            color: #0b2051;
          }

          &:last-child {
            color: #575757;
          }
        }
      }

      .degree {
        display: flex;
        align-items: center;
        padding-left: 10px;
        padding-top: 9px;

        span {
          font-size: 13px;
          line-height: 150%;
          color: #575757;
          margin-bottom: 0;

          &:first-child {
            font-size: 16px;
            margin-right: 1rem;
            color: #0b2051;
          }

          &:last-child {
            color: #575757;
          }
        }
      }

      .reviews {
        display: flex;
        align-items: center;
        padding-right: 10px;
        padding-top: 9px;
        border-right: 1px solid #dee2e6;

        span {
          font-size: 14px;
          line-height: 150%;
          color: #575757;
          margin-bottom: 0;

          &:first-child {
            font-size: 16px;
            margin-right: 1rem;
            color: #0b2051;
          }

          &:last-child {
            color: #575757;
          }
        }
      }
    }
  }
}

.testimonials {
  .testimonial__box {
    padding: 30px;
    border: 1px solid #c4d3f8;
    border-radius: 6px;
    background-color: #fff;
    box-shadow: 0 1px 13px 0 rgba(54,127,211,.18);
    max-width: 300px;
  }

  .btn-outline-secondary {
    box-shadow: none;
  }

  .testimonial__box-header {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 1rem;

    .client__avatar {
      margin-right: 1.5rem;

      img {
        border-radius: 50%;
      }
    }

    .client__info {
      h4 {
        font-size: 16px;
        line-height: 150%;
        color: #0b2051;
        margin-bottom: 0;
      }
    }
  }
}

.banner {
  .banner__box {
    padding: 50px 15px;
    background-color: #0b2051;
    border-radius: 13px;

    h2 {
      font-size: 45px;
      line-height: 150%;
      font-weight: 700;
      color: #fff;
      margin-bottom: 2rem;
    }

    p {
      font-size: 18px;
      line-height: 150%;
      font-weight: 600;
      color: #fff;
      margin-bottom: 2rem;
    }

    .banner__box-body {
      max-width: 980px;
      margin: 0 auto;
    }
  }
}

.hiw {
  .hiw__content {
    .step__number {
      color: #f15529;
      font-size: 30px;
    }
  }
}

.writer {
  position: relative;

  .writer__sidebar {
    padding: 30px;
    border: 1px solid #c4d3f8;
    border-radius: 6px;
    background-color: #fff;
    text-align: center;
    box-shadow: 0 1px 13px 0 rgba(54,127,211,.18);
    position: sticky;
    top: 60px;
  }

  .writer__avatar {
    img {
      width: 95px;
      height: 95px;
      padding: 8px;
      border: 1px solid #f15529;
      border-radius: 50%;
    }
  }

}

.seo__section {
  .seo__section-wrapper {
    padding: 85px 110px 0 60px;
    background-color: #f5f7ff;
    height: 800px;
    overflow-y: scroll;
    border-radius: 13px;
  }
}

.features {
  .features__content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 2.5rem;
  }

  .feature__box-header {
    display: flex;
    flex-direction: row;

    img {
      margin-right: 1.5rem;
      filter: brightness(0) saturate(100%) invert(46%) sepia(12%) saturate(6725%) hue-rotate(345deg) brightness(98%) contrast(92%);
    }
  }
}

.posts {
  .post-image {
    overflow: hidden;

    img {
      transition: transform .5s ease;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
  .post-title {
    a {
      text-decoration: none;
      font-weight: 700;
      color: rgb(55,55,55);
    }
  }
}

.writer__testimonials {
  .testimonial__box {
    padding: 30px;
    border: 1px solid #c4d3f8;
    border-radius: 6px;
    background-color: #fff;
    box-shadow: 0 1px 13px 0 rgba(54,127,211,.18);
  }

  .testimonial__box-header {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 1rem;

    .client__avatar {
      margin-right: 1.5rem;

      img {
        border-radius: 50%;
      }
    }

    .client__info {
      h4 {
        font-size: 16px;
        line-height: 150%;
        color: #0b2051;
        margin-bottom: 5px;
      }
    }
  }
}

.blog__aside {
  .blog__banner {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 40px 35px;
    position: sticky;
    top: 25px;
    width: 100%;
    background-color: #0b2051;
    border-radius: 13px;
  }
}

.faqs {
  .faq__content {
    .accordion {
      .show {
        .accordion-body {
          padding: 30px;
        }
      }
    }

    button {
      box-shadow: none !important;
      font-size: 20px;
      font-weight: 700;
      color: #000;
      padding: 30px;

      &:not(.collapsed) {
        background-color: #fff;

        &:after {
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23f15529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
        }

        &:before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 5%;
          width: 90%;
          height: 2px;
          background-color: #f15529;
        }
      }
    }

    .accordion-item {
      margin-bottom: 1.5rem;
      box-shadow: 0 1px 13px 0 rgba(54,127,211,.18);
      border: 0 !important;
      border-radius: 13px;
    }
  }
}

//---------------------------------------------
// 3. Footer
//---------------------------------------------
.footer {
  padding-top: 60px;
  padding-bottom: 60px;

  .footer__widget {
    h5 {
      margin-bottom: 1.5rem;
      color: rgba(255,255,255,.55);
    }

    ul {
      padding-left: 0;
      list-style: none;

      li {
        margin-bottom: .25rem;

        a {
          text-decoration: none;
          color: rgba(255,255,255,.85) !important;
        }
      }
    }
  }

  .footer__top {
    padding-top: 60px;
  }

  .disclaimer {
    p {
      font-size: 0.85rem;
      color: rgba(255,255,255,.55);
    }
  }

  .footer__links-list a {
    text-decoration: none;
    border-right: 1px solid rgba(255,255,255,.55);
    color: rgba(255,255,255,.55);
    font-size: 15px;
    line-height: 14px;
    margin: 4px;
    padding-right: 7px;

    &:hover {
      text-decoration: none;
      color: #fff !important;
    }

    &:last-child {
      border-right: none;
    }
  }

  .footer__bottom {
    padding-top: 40px;

    p {
      font-size: 0.85rem;
      color: rgba(255,255,255,.85);
    }

    a {
      text-decoration: none;
      color: rgba(255,255,255,.55);

      &:hover {
        text-decoration: underline;
        color: #fff !important;
      }
    }
  }
}

@media (max-width: 768px) {
  .team__content {
    grid-auto-columns: 1fr 1fr;
  }

  .blog__aside {
    display: none;
  }
}

@media (max-width: 480px) {
  .hero {
    .ratings {
      display: none;
    }
  }

  .team__content {
    grid-auto-columns: 1fr 1fr;

    .profile__card {
      height: auto;
      flex-direction: column;

      .writer__stats {
        grid-template-columns: 1fr;

        .finished__orders, .degree {
          border: 0;
          padding-left: 0;
        }

        .reviews {
          padding-top: 9px;
          border: 0;
        }

        .success__rate {
          padding-left: 0;
          padding-top: 0;
        }
      }
    }
  }

  .team {
    .writer__card {
      height: auto;
      flex-direction: column;
      width: 320px;

      .writer__grid {
        grid-template-columns: 1fr;
      }

      .writer__stats {
        grid-template-columns: 1fr;

        .finished__orders, .degree {
          border: 0;
          padding-left: 0;
        }

        .reviews {
          padding-top: 9px;
          border: 0;
        }

        .success__rate {
          padding-left: 0;
          padding-top: 0;
        }
      }
    }

    .writer__content {
      padding-left: 10px !important;
      border-left: 0 !important;
    }
  }
}
